@if (authService.loggedIn && (translations$ | async) !== null && (headerConfig$ | async); as headerConfig) {
  @if (!headless) {
    <mat-menu #tenantMenu="matMenu">
      @for (tenant of tenantService.userAllowedTenants$ | async; track tenant) {
        <button mat-menu-item (click)="tenantService.activate(tenant)">
          @if ((tenantService.activeTenant$ | async) === tenant.id) {
            <mat-icon svgIcon="ak-check">(active)</mat-icon>
          }
          <span> {{ tenant.displayName ?? tenant.id }} </span>
        </button>
      }
    </mat-menu>
    <mat-menu #debuggerMenu="matMenu">
      <button mat-menu-item (click)="showAppInfo()">
        <mat-icon svgIcon="ak-info"></mat-icon>
        <span>{{ 'core.menu.information' | translation }}</span>
      </button>
      <button mat-menu-item (click)="debugTranslationToggle()">
        <mat-icon svgIcon="ak-globe-web"></mat-icon>
        <span>{{ 'core.menu.translations' | translation }}</span>
      </button>
      <button mat-menu-item aria-label="contacts" (click)="goToContacts()">
        <mat-icon svgIcon="ak-contacts"></mat-icon>
        <span>{{ 'core.menu.contacts' | translation }}</span>
      </button>
    </mat-menu>
    <mat-menu #menu="matMenu" class="header-menu">
      @if (userProfile$ | async; as userData) {
        <button mat-menu-item (click)="openURL(headerConfig.profile)" [disabled]="!userData || !headerConfig.profile">
          <mat-icon svgIcon="ak-person"></mat-icon>
          <span>{{ userData?.name }}</span>
        </button>
      }
      <!-- if multiple options are available we create a menu for selecting the tenant -->
      @if ((userAllowedTenants$ | async)?.length > 1) {
        <button mat-menu-item [matMenuTriggerFor]="tenantMenu">
          <span> tenant ({{ tenantService.activeTenant$ | async }})</span>
        </button>
      } @else {
        <span mat-menu-item [disabled]="true"> tenant ({{ tenantService.activeTenant$ | async }})</span>
      }
      <!-- if only one tenant option is available we display it as a disabled menu item -->
      @if (headerConfig.calendar) {
        <button mat-menu-item aria-label="calendar" (click)="openURL(headerConfig.calendar)">
          <mat-icon svgIcon="ak-event"></mat-icon>
          <span>{{ 'core.menu.calendar' | translation }}</span>
        </button>
      }
      @if (headerConfig.deskBooking) {
        <button mat-menu-item aria-label="desk booking" (click)="openURL(headerConfig.deskBooking)">
          <mat-icon svgIcon="ak-reception"></mat-icon>
          <span>{{ 'core.menu.desk-booking' | translation }}</span>
        </button>
      }
      @if (!fullWidth) {
        <button mat-menu-item aria-label="fullscreen" (click)="toggleFullscreen()">
          <mat-icon svgIcon="ak-fullscreen"></mat-icon>
          <span>{{ 'core.menu.fullscreen' | translation }}</span>
        </button>
      }
      @if (fullWidth) {
        <button mat-menu-item aria-label="fullscreen exit" (click)="toggleFullscreen()">
          <mat-icon svgIcon="ak-fullscreen-exit"></mat-icon>
          <span>{{ 'core.menu.fullscreen-exit' | translation }}</span>
        </button>
      }
      @if (headerConfig.remoteDesktop && (showDebuggerMenu$ | async)) {
        <button mat-menu-item (click)="openURL(headerConfig.remoteDesktop)">
          <mat-icon svgIcon="ak-remote-desk-booking"></mat-icon>
          <span>{{ 'core.menu.remote-desktop' | translation }}</span>
        </button>
      }
      @if (showDebuggerMenu$ | async) {
        <button mat-menu-item [matMenuTriggerFor]="debuggerMenu">
          <mat-icon svgIcon="ak-gear"></mat-icon>
          <span>{{ 'core.menu.debug' | translation }}</span>
        </button>
      }
      <button mat-menu-item (click)="showSystemInfos()">
        <mat-icon>info_outline</mat-icon>
        <span>{{ 'core.menu.about' | translation }}</span>
      </button>
      <button mat-menu-item (click)="onLogout()">
        <mat-icon svgIcon="ak-exit-app"></mat-icon>
        <span>{{ 'core.menu.logout' | translation }}</span>
      </button>
    </mat-menu>
    <mat-sidenav-container [ngClass]="{ 'fixed-height': fixedHeight$ | async }">
      <mat-sidenav #drawer [fixedInViewport]="true" [autoFocus]="false">
        <div class="sidenav-header">
          @if (headerConfig.title) {
            <span (click)="goHome()" (keydown.enter)="goHome()" [tabindex]="0" class="app-name">{{ headline }}</span>
          }
          <span class="spacer"></span>
          <button mat-icon-button aria-label="Close Sidenav" (click)="drawer.toggle()">
            <mat-icon svgIcon="ak-close"></mat-icon>
          </button>
        </div>
        @if (menuItems$ | async; as menuItems) {
          <ak-menu [menuItems]="menuItems" (menuClicked)="drawer.close()"></ak-menu>
        }
      </mat-sidenav>
      <mat-sidenav-content>
        <main>
          <mat-toolbar [attr.data-selector]="'toolbar'">
            <mat-toolbar-row>
              <button mat-icon-button class="header-button" aria-label="Toggle sidenav" (click)="drawer.toggle()">
                <mat-icon svgIcon="ak-menu"></mat-icon>
              </button>
              @if (headerConfig.title) {
                <span (click)="goHome()" (keydown.enter)="goHome()" [tabindex]="0" class="app-name">{{
                  headline
                }}</span>
              }
              <span class="spacer"></span>
              <button
                data-test-header-btn-language
                mat-icon-button
                class="header-button"
                aria-label="Change Language"
                (click)="selectLanguage()"
              >
                <mat-icon svgIcon="ak-globe-web"></mat-icon>
              </button>
              @if (headerConfig.help) {
                <button
                  data-test-header-btn-service-desk
                  mat-icon-button
                  class="header-button"
                  aria-label="Support"
                  (click)="openURL(headerConfig.help)"
                >
                  <mat-icon svgIcon="ak-support"></mat-icon>
                </button>
              }
              @if (headerConfig.search) {
                <button
                  data-test-header-btn-search
                  mat-icon-button
                  class="header-button"
                  aria-label="Search"
                  (click)="openSearch()"
                >
                  <mat-icon svgIcon="ak-search" class="large-icon"></mat-icon>
                </button>
              }
              <ak-user-picture
                [svgIcon]="'ak-person'"
                [menuTriggerFor]="menu"
                [src]="(userProfile$ | async)?.picture"
              ></ak-user-picture>
            </mat-toolbar-row>
          </mat-toolbar>
          <div
            [ngClass]="{ container: true, 'container-constrained-height': fixedHeight$ | async }"
            [class.full-width]="fullWidth"
          >
            @if (!breadcrumbless) {
              <div>
                <app-breadcrumbs [attr.data-selector]="'breadcrumbs'" class="breadcrumbs"></app-breadcrumbs>
              </div>
            }
            <div class="content-router-outlet">
              <router-outlet></router-outlet>
            </div>
          </div>
        </main>
      </mat-sidenav-content>
    </mat-sidenav-container>
  }
  @if (headless) {
    <div class="container-fluid">
      @if (!breadcrumbless) {
        <div class="row">
          <app-breadcrumbs [attr.data-selector]="'breadcrumbs'" class="breadcrumbs col-12"></app-breadcrumbs>
        </div>
      }
      <div class="row">
        <div class="col">
          <router-outlet></router-outlet>
        </div>
      </div>
    </div>
  }
} @else {
  <div class="spinner-container">
    <mat-spinner></mat-spinner>
  </div>
}
<ng-template #spinner>
  <div class="spinner-container">
    <mat-spinner></mat-spinner>
  </div>
</ng-template>
